<template>
  <div class="msg-feedback" ref="feedbackItem">
    <bubble-tip class="feedback-title" :message="{
      content: '您对人工客服的服务满意吗'
    }" />
    <div class="feedback-ctner">
      <div
        class="choose-item"
        :class="{ selected: selectTag === idx }"
        @click="getScore(item)"
        :key="idx"
        v-for="(item, idx) in scores"
      >
        <div class="choose-item__icon icon_1"></div>
        <div class="choose-item__text">{{ item.text }}</div>
      </div>
    </div>
    <!-- 提交的按钮 -->
    <div class="button-group" v-show="!isCommited && selected !== -1">
      <button class="is-default" @click="confirm(false)">放弃评价</button>
      <button @click="confirm(true)">提交评价</button>
    </div>
  </div>
</template>
<script>
import BubbleTip from './Tip'

export default {
  data () {
    return {
      tplId: '',
      selected: -1,
      isCommited: false,
      scores: [
        {
          score: 1,
          text: '非常满意'
        },
        {
          score: 2,
          text: '满意'
        },
        {
          score: 3,
          text: '一般'
        },
        {
          score: 4,
          text: '不满意'
        },
        {
          score: 5,
          text: '很不满意'
        }
      ]
    }
  },
  components: {
    BubbleTip
  },
  computed: {
    selectTag () {
      return this.scores.indexOf(this.selected)
    }
  },
  props: ['message'],
  methods: {
    getScore (item) {
      if (this.isCommited) {
        return false
      }
      this.selected = item
    },
    confirm (isConfirmed) {
      if (this.isCommited) {
        return false
      }
      if (isConfirmed) {
        this.$store.dispatch('StaffFeedbackScore', this.selected.score)
      }
      this.isCommited = true
    }
  }
}
</script>
<style lang="scss" scoped>
.bubble-type-commit {
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin-bottom: 0;
  .feedback-title {
    // font-size: 22px;
    // color: #909399;
    // margin: 0;
    // font-weight: normal;
    margin-bottom: 40px;
  }
  .feedback-ctner {
    padding: 0.2rem 0;
    background: #fff;
    border-radius: 0.1rem;
    &:after {
      content: '';
      display: table;
      clear: both;
    }
    .choose-item {
      float: right;
      width: 20%;
      min-width: 4em;
      /*margin-left: .05rem;
        margin-right: .05rem;*/
      text-align: center;
      color: #555;
      text-align: center;
      .choose-item__icon {
        margin: 0 auto 0.16rem auto;
        width: 2em;
        height: 2em;
        background-repeat: no-repeat;
        background-position: center center;
        -webkit-background-size: contain;
        background-size: contain;
        &[class*='icon_'] {
          background-image: url('../../assets/images/score_dft.png');
        }
      }
      .choose-item__text {
        color: #acacac;
        font-size: 85% !important;
      }

      &.selected,
      &.selected ~ .choose-item {
        [class*='icon_'] {
          background-image: url('../../assets/images/score_select.png');
        }
      }
      &.selected .choose-item__text {
        color: #41b6f8;
      }
    }
  }
  .button-group {
    display: flex;
    width: 100%;
    margin-top: 30px;
    transition: all ease 0.1s;
    button {
      border: 0;
      outline: 0;
      height: 60px;
      line-height: 60px;
      @include background_color('theme-color-1');
      color: #fff;
      border-radius: 6px;
      flex: 1;
      &:first-child {
        margin-right: 25px;
      }
      &:last-child {
        margin-left: 25px;
      }
      &.is-default {
        background-color: #c6c6c6 !important;
      }
    }
  }
}
</style>
